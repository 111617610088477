const configure = {
    state:{
        HOST: 'https://spbo.52101314.xyz',  //后台访问地址根目录
    },
    getters: {
              
    },
    mutations: {
        
    }
}

export default configure